import { IOption } from 'types/IOption';
import { textsCommon, textsUser } from 'texts/index';
import routes from 'config/routes';
import { ThemeConfig } from 'antd';

export const TABLE_PAGE_SIZE = 25;
export const TABLE_PAGE_SIZE_LARGE = 50;
export const TABLE_PAGE_SIZE_MOBILE = 10;

export const XS_WIDTH = 480;
export const SM_WIDTH = 576;
export const MD_WIDTH = 768;
export const LG_WIDTH = 992;
export const XL_WIDTH = 1200;
export const XLL_WIDTH = 1400;
export const XXL_WIDTH = 1600;

export const DATE_TRANSFER_FORMAT = 'YYYY-MM-DD';
export const DATE_TIME_TRANSFER_FORMAT = 'YYYY-MM-DD HH:mm';
export const DATE_TIME_FULL = 'YYYY-MM-DD HH:mm:ss';
export const DATE_CUSTOM = 'date-custom';
export const DATE_TIME_VISUAL = 'YYYY-MM-DD h:mm a';
export const DATE_TIME_VISUAL_FULL_WITH_SECONDS = 'YYYY-MM-DD HH:mm:ss a';
export const DATE_VISUAL = 'YYYY-MM-DD';
export const format12Hours = 'h:mm a';
export const format24Hours = 'HH:mm';
export const DAY_MONTH_SHORT = 'DD-MMM';
export const DAY_MONTH_SHORT_TIME = 'DD MMM h:mm a';

export const DESCENT = 'descend';
export const ASCENT = 'ascend';

export enum SortOrders {
    ascend = 'ascend',
    descend = 'descend',
}

export const intercomRoutesProps = {
    useApp: 'use_app',
    usePhone: 'use_phone',
};

export const cardActions = {
    assign: 'assign',
    remove: 'remove',
};

export const DEFAULT_COLOR = '#4119F3';

export enum Roles {
    admin = 'admin',
    user = 'user',
}

export const userRoles: IOption[] = [
    {
        label: textsCommon.userRoles.user,
        value: Roles.user,
    },
    {
        label: textsCommon.userRoles.admin,
        value: Roles.admin,
    },
];

export const intercomRoutes: IOption[] = [
    {
        label: textsUser.videoCallSwiftlaneApp,
        value: intercomRoutesProps.useApp,
    },
    {
        label: textsUser.regularPhoneCall,
        value: intercomRoutesProps.usePhone,
    },
];

export const sortOrders: IOption[] = [
    {
        label: 'Asc',
        value: SortOrders.ascend,
    },
    {
        label: 'Desc',
        value: SortOrders.descend,
    },
];

export const phoneCountryTxtCodes: string[] = [
    'us',
    'ca',
    'fr',
    'am',
    'ua',
    'ba',
    'it',
    'ro',
    'ch',
    'gb',
    'dk',
    'se',
    'pl',
    'de',
    'mx',
    'ph',
    'nz',
    'kz',
    'jp',
    'kr',
    'cn',
    'tw',
    'in',
    'pk',
];

export const DASH = '-';

export enum EAddRemove {
    add = 'add',
    remove = 'remove',
}

export enum EAddRemoveCamel {
    add = 'Add',
    remove = 'Remove',
}

export enum EYesNo {
    yes = 'yes',
    no = 'no',
}

export const routeId = ':id';
export const routeDeviceId = ':deviceId';
export const routeSiteId = ':siteId';
export const all = 'all'; // use it for selects

export const supportEmail = 'support@swiftlane.com';
export const accountingEmail = 'accounting@swiftlane.com';
export const salesEmail = 'sales@swiftlane.com';
export const sbEmail = 'sb@swiftlane.com';
export const AKUVOX_MODEL = 'X915';

export enum Statuses {
    active = 'Active',
    inActive = 'InActive',
    configurationPending = 'Configuration Pending',
    authenticationFailed = 'Authentication Failed',
}

export const userSettingsStorageKey = 'userSettings';

export const publicRoutes = [
    routes.auth.enterWorkspace,
    routes.auth.forgotPassword,
    routes.auth.joinWorkspace,
    routes.auth.confirmEmail,
    routes.auth.login,
    routes.auth.emailVerification,
];

export const latestFirmwareVers = '2.1.0';

export const theme: ThemeConfig = {
    token: {
        colorBgLayout: '#f5f5f5',
        colorPrimary: '#4119f3',
        borderRadius: 4,
        colorError: '#F20D59',
        fontFamily: 'Inter, sans-serif',
        colorBorder: '#D1D1E0',
        colorBorderSecondary: '#D1D1E0',
        colorLink: '#4119f3',
        colorLinkHover: '#355077',
        fontSize: 14,
    },
    components: {
        Layout: {
            bodyBg: '#f5f5f5',
        },
        Button: {
            fontSize: 12,
        },
        Select: {
            fontSize: 12,
            colorText: '#52527a',
            colorTextPlaceholder: '#aaa',
        },
        Input: {
            fontSize: 12,
            colorText: '#52527a',
            colorTextPlaceholder: '#aaa',
        },
    },
};
