import { ReqAddAdmin } from 'pages/Users/types';
import { APIMethods, apiProxy, Message, TResponse } from 'utils/api/apiRequestProxy';
import { QueryFunctionContext } from 'react-query/types/core/types';
import { AccountSummary, IWorkspace, IWorkspaceSettings, ReqUpdateAccount, ReqWorkspacesQuery } from './types';

export async function requestAddAdmin(data: ReqAddAdmin): Promise<TResponse<Message>> {
    return apiProxy<Message>({
        url: '/users/add_admin/',
        method: APIMethods.POST,
        data,
    });
}

export async function requestAccounts({ queryKey }: QueryFunctionContext<[string, ReqWorkspacesQuery]>): Promise<TResponse<IWorkspace[]>> {
    const [, query] = queryKey;
    return apiProxy<IWorkspace[]>({
        url: '/admin/workspaces/',
        method: APIMethods.GET,
        params: query,
        respKey: 'workspaces',
    });
}

export async function requestAccount({ queryKey }: QueryFunctionContext<[string, string]>): Promise<TResponse<IWorkspace>> {
    const [, id] = queryKey;
    return apiProxy<IWorkspace>({
        url: `/admin/workspaces/${id}`,
        method: APIMethods.GET,
    });
}

export async function requestUpdateAccount(data: ReqUpdateAccount): Promise<TResponse<Message>> {
    const { id, ...payload } = data;
    return apiProxy<Message>({
        url: `/admin/workspaces/${id}`,
        method: APIMethods.PATCH,
        data: payload,
    });
}

export async function requestAuthAccount(): Promise<TResponse<IWorkspace>> {
    return apiProxy<IWorkspace>({
        url: '/workspace/',
        method: APIMethods.GET,
        respKey: 'workspace',
    });
}

export async function requestGetAccountSummary(accountId: string): Promise<TResponse<AccountSummary>> {
    return apiProxy<AccountSummary>({
        url: '/account-summary',
        method: APIMethods.GET,
        params: { company_id: accountId },
    });
}

export async function requestGetAccountSettings(accountId: string): Promise<TResponse<IWorkspaceSettings>> {
    return apiProxy<IWorkspaceSettings>({
        url: '/workspace/cap_settings/',
        method: APIMethods.GET,
        params: { company_id: accountId },
        respKey: 'settings',
    });
}
