import { UAParser } from 'ua-parser-js';

const generateUUID = (): string => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
        // eslint-disable-next-line no-bitwise
        const r = (Math.random() * 16) | 0;
        // eslint-disable-next-line no-bitwise
        const v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
};

export const getDeviceInfo = (): { id: string; name: string } => {
    const info = JSON.parse(localStorage.getItem('deviceInfo') || '{}');

    if (info.id && info.name) {
        return {
            id: info.id,
            name: info.name,
        };
    }

    const { os, browser } = UAParser(navigator.userAgent);

    const deviceInfo = {
        id: generateUUID(),
        name: `${os.name} ${os.version} - ${browser.name} ${browser.version}`,
    };

    localStorage.setItem('deviceInfo', JSON.stringify(deviceInfo));

    return deviceInfo;
};
