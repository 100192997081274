export default {
    title: 'Hardware',
    desc: 'Configure all your hardware devices',
    addNewReader: 'New Key Card Reader',
    addReaderDesc: 'Configure a new key card reader into the Swiftlane system',
    keyCardReaders: 'Key Card Readers',
    doorControllers: 'Door Controllers',
    newDoorController: 'New Door Controller',
    updateConfirm: 'Are you sure you want to update?',
    deviceDetails: 'Device Details',
    smartLocks: 'Smart Locks',
    type: 'Type',
    model: 'Model',
    hubId: 'Hub ID',
    lockUsers: 'Lock Users',
    unit: 'Unit',
    lockName: 'Lock Name',
    lockDetails: 'Lock Details',
    deviceInfo: 'Device Info',
    lockId: 'Lock ID',
    lockStatus: 'Lock Status',
    hubStatus: 'Hub Status',
    masterPin: 'Master PIN',
    activePins: 'Active PINs',
    pinLimit: 'PIN Limit',
    bleAccessSupported: 'BLE Access Supported',
    keycardAccessSupported: 'Keycard Access Supported',
    pinAccessSupported: 'PIN Access Supported',
    pinScheduleSupported: 'PIN Schedule Supported',
    language: 'Language',
    voice: 'Voice',
    autoMode: 'Auto Mode',
    awayMode: 'Away Mode',
    insideDeadLock: 'Inside Dead Lock',
    powerSaverMode: 'Power Saver Mode',
    selectUnit: 'Select Unit',
    unlock: 'Unlock',
    userName: 'User Name',
    userType: 'User Type',
    pinStatus: 'PIN Status',
    pins: 'PINs',
    syncPin: 'Sync PIN',
    lockPins: 'Lock PINs',
    accessLogs: 'Access Logs',
    accessType: 'Access Type',
    logs: 'Logs',
    remoteUnlock: 'Remote Unlock',
    mobileOnly: 'Mobile Only',
    pinUnlock: 'PIN Unlock',
    mappedReaders: 'Mapped Readers',
    deleteResidentPINWarning: 'This is a Resident PIN. Deleting will un-assign the user from the unit. Are you sure you want to proceed?',
    unitUsers: 'Unit Users',
    addLock: 'Add Lock',
    selectLockType: 'Please Select Lock Type',
    augustLocks: 'August Locks',
    battery: 'Battery',
    lastOnline: 'Last Online',
    lastOffLine: 'Last offline',
    retry: 'Retry',
    setPin: 'Set Pin',
    mapUnitConfirmMsg: 'If you change the unit, all users mapped to this Unit will loose access to the lock. Do you want to proceed?',
    pinHelpTxt: 'Please Provide min 4 and max 6 digits pin number',
    pin: 'Pin',
    notificationSettings: 'Notification Settings',
    receiveAdminNotifications: 'Notify Admin on Lock Events',
    receiveResidentNotifications: 'Notify Resident on Lock Events',
    notifyHelpTxt: 'Admin/resident will get email notifications when lock status changes (online/offline)',
    mainInfo: 'Main Info',
    assignedUsers: 'Assigned Users',
    selectUsersAssignTo: 'Please Select users you would like to assign to',
    createPin: 'Create Pin',
    assignedUsersHelpTxt: 'Below is the list of users who are already assigned to the lock',
    assignUsersBtnHelpTxt: 'Assign users to the unit who can access this lock',
    createNewPin: 'Create New PIN',
    enterPinDesc: 'Please Enter main pass info',
    pinAccessPlaceDesc: 'The pin created will be usable on this lock',
    pinOptionalDesc: 'Please Enter visitor details if needed',
    syncHelpTxt: 'Try again to sync Pin with the lock',
    resident: 'Resident',
    removeLock: 'Remove Lock',
    augustLockRemoveConfirm:
        "If the lock is currently offline, the pins created by swiftlane system won't be deleted. You will have to use august app to delete those pins later or reset the lock.",
    selectSiteFirst: 'Please select Site first',
    pinName: 'Pin Name',
    integrationAccountInfo: 'Integration Account Info',
    smartLocksSupportSubject: 'Purchase Smart Lock Credits',
    smartLocksSupportBody:
        'Hi Swiftlane Support Team, We need to add X number of new Smart Locks to our account. Kindly help us to add required credits to the account.',
    maxReachedSmartLocksMsg:
        'You have reached max number of smart locks allowed to add in Swiftlane. If you need to add more locks to Swiftlane, please contact Swiftlane Support at ',
    smartLocksAddNewMsg: 'If you need to add more locks to Swiftlane, please contact Swiftlane Support at',
    swiftReaderX: 'Swiftreader X',
    intercomReaders: 'Intercom Readers',
    assignUnit: 'Assign Unit to Lock',
    removeLockFromUnitConfirm:
        'Are you sure you want to remove the unit from this lock? All the users mapped to the unit will loose access to the lock.',
    removeLockFromUnitHint: 'This will remove the unit from the lock and all the users mapped to the unit will loose access to the lock.',
    createUnit: 'Create Unit',
    acu: {
        title: 'ACU devices',
    },
    augustYale: 'August & Yale',
    iglooHome: 'Igloo',
    alfred: 'Alfred',
};
