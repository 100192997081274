export default {
    addUnit: 'Add Unit',
    editUnit: 'Edit Unit',
    unitInformation: 'Unit Information',
    unitName: 'Unit Name',
    active: 'Active',
    inactive: 'Inactive',
    available: 'Available',
    occupied: 'Occupied',
    vacant: 'Vacant',
    selectSite: 'Select Site',
    selectUsers: 'Select Users',
    locks: 'Locks',
    selectLocks: 'Select Locks',
    lock: 'Lock',
    selectLock: 'Select Lock',
    locksMapping: 'Assign locks to Unit',
    mapLocksToUnit: 'Select Locks To assign to the Unit',
    selectFloor: 'Select Floor',
    floor: 'Floor',
    unitDetails: 'Unit Details',
    unitUsersTitle: 'Assign users to Unit',
    addUsersTitle: 'Add users to Unit',
    assignResidents: 'Assign residents to Unit',
    addResidents: 'Add residents to Unit',
    assignedUsersHelpTxt: 'Below is the list of users who are already assigned to the Unit',
    addUsersHelpTxt: 'Below is the list of users who will be added to the Unit',
    assignedResidentsHelpTxt: 'Below is the list of residents who are already assigned to the Unit',
    addedResidentsHelpTxt: 'Below is the list of residents who will be added to the Unit',
    addUsers: 'Add Users',
    selectUsersToAdd: 'Select user to add to the Unit',
    createUnit: 'Create Unit',
    virtualPhoneNumber: 'Virtual Phone Number',
    updateSite: 'Update Site',
    deleteMessage: 'To delete this unit please contact support',
    deleteSelectedMessage: 'To delete selected units please contact support',
    addResidentsBtn: 'Add Residents',
};
