import React, { FC, useEffect, useRef } from 'react';
import { LocalParticipant } from 'twilio-video/tsdef/LocalParticipant';
import { RemoteParticipant } from 'twilio-video/tsdef/RemoteParticipant';
import { AudioTrack, VideoTrack } from 'twilio-video';
import { ICustomMap } from 'types/ICustomMap';

interface IProps {
    participant: LocalParticipant | RemoteParticipant | undefined;
}

const Participant: FC<IProps> = ({ participant }: IProps) => {
    const videoRef = useRef<HTMLVideoElement | null>(null);
    const audioRef = useRef<HTMLAudioElement | null>(null);

    useEffect(() => {
        const trackSubscribed = (track: VideoTrack | AudioTrack) => {
            if (track.kind === 'video') {
                track.attach(videoRef.current as HTMLMediaElement);
            } else {
                track.attach(audioRef.current as HTMLMediaElement);
            }
        };

        // const trackUnsubscribed = (track: VideoTrack | AudioTrack) => {
        //     track.detach();
        // };

        if (participant) {
            participant.on('trackSubscribed', trackSubscribed);
            // participant.on('trackUnsubscribed', trackUnsubscribed);
            participant.on('trackPublished', ({ track }) => {
                if (track) {
                    trackSubscribed(track);
                }
            });
            // participant.on('trackUnpublished', ({ track }) => {
            //     if (track) {
            //         trackUnsubscribed(track);
            //     }
            // });

            participant.tracks.forEach((publication: ICustomMap) => {
                if (publication.isTrackEnabled && publication.track) {
                    trackSubscribed(publication.track);
                }
            });
        }

        return () => {
            participant?.removeAllListeners();
        };
    }, [participant]);

    return (
        <>
            {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
            <video ref={videoRef} autoPlay />
            {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
            <audio ref={audioRef} autoPlay />
        </>
    );
};

export default Participant;
