import { useEffect } from 'react';

const useWindowVisibilityChange = () => {
    useEffect(() => {
        const handleVisibilityChange = () => {
            if (document.visibilityState === 'visible') {
                // console.log('Tab is active');
                // Tab is active, you can pause or stop showing custom notifications and playing sounds here
                if (typeof window !== 'undefined') {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    (window as any).isTabVisible = true;
                }
            } else {
                // console.log('Tab is inactive');
                // Tab is inactive, you can proceed to show custom notifications and play sounds here
                // eslint-disable-next-line no-lonely-if
                if (typeof window !== 'undefined') {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    (window as any).isTabVisible = false;
                }
            }
        };
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any).isTabVisible = true;
        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, []);
};

export default useWindowVisibilityChange;
