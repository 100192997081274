import React, { FC } from 'react';

const IntercomIcon: FC = () => (
    <svg fill='#000000' height='800px' width='800px' version='1.1' viewBox='0 0 416.98 416.98' xmlSpace='preserve'>
        <g>
            <path
                d='M310.099,0H106.882C90.541,0,77.246,13.294,77.246,29.635v357.709c0,16.341,13.295,29.636,29.636,29.636h203.217
c16.341,0,29.635-13.294,29.635-29.636V29.635C339.734,13.294,326.44,0,310.099,0z M324.734,387.344
c0,8.07-6.565,14.636-14.635,14.636H106.882c-8.07,0-14.636-6.565-14.636-14.636V29.635c0-8.07,6.565-14.635,14.636-14.635h203.217
c8.069,0,14.635,6.565,14.635,14.635V387.344z'
            />
            <path
                d='M287.987,176.216H128.994c-4.143,0-7.5,3.358-7.5,7.5v43.498c0,4.142,3.357,7.5,7.5,7.5h158.992c4.143,0,7.5-3.358,7.5-7.5
v-43.498C295.487,179.574,292.129,176.216,287.987,176.216z M208.377,191.216h42.978l-7.862,28.498h-42.978L208.377,191.216z
M184.956,219.714H170.25l7.862-28.498h14.705L184.956,219.714z M136.494,191.216h26.058l-7.862,28.498h-18.197V191.216z
M280.487,219.714h-21.434l7.862-28.498h13.573V219.714z'
            />
            <path
                d='M208.49,158.193c34.736,0,62.997-28.261,62.997-62.998s-28.261-62.997-62.997-62.997s-62.997,28.26-62.997,62.997
S173.754,158.193,208.49,158.193z M208.49,47.198c26.466,0,47.997,21.531,47.997,47.997s-21.531,47.998-47.997,47.998
s-47.997-21.532-47.997-47.998S182.025,47.198,208.49,47.198z'
            />
            <path
                d='M208.49,131.944c20.263,0,36.748-16.485,36.748-36.749s-16.485-36.749-36.748-36.749
c-20.264,0-36.749,16.485-36.749,36.749S188.227,131.944,208.49,131.944z M208.49,73.447c11.992,0,21.748,9.756,21.748,21.749
s-9.756,21.749-21.748,21.749s-21.749-9.756-21.749-21.749S196.498,73.447,208.49,73.447z'
            />
            <path
                d='M207.774,259.999c-2.924-1.972-6.972-1.555-9.461,0.93c-2.046,2.042-2.732,5.176-1.735,7.885
c1.094,2.975,4.039,5.001,7.22,4.92c3.164-0.081,5.984-2.208,6.953-5.212C211.753,265.413,210.548,261.782,207.774,259.999
C207.373,259.729,208.194,260.269,207.774,259.999z'
            />
            <path
                d='M210.543,290.369c-1.197-2.83-4.025-4.703-7.112-4.628c-3.152,0.076-5.926,2.171-6.931,5.143
c-0.992,2.935,0.028,6.337,2.473,8.242c2.553,1.989,6.102,2.114,8.801,0.343C210.75,297.516,211.849,293.64,210.543,290.369
C210.353,289.918,210.723,290.819,210.543,290.369z'
            />
            <path
                d='M210.543,317.369c-1.122-2.711-3.807-4.557-6.744-4.628c-3.213-0.077-6.148,1.958-7.24,4.969
c-1.087,2.998-0.073,6.481,2.445,8.434c2.627,2.037,6.319,2.091,9.017,0.157C210.824,324.293,211.809,320.539,210.543,317.369
C210.353,316.909,210.723,317.819,210.543,317.369z'
            />
            <path
                d='M207.774,340.999c-2.83-1.908-6.749-1.59-9.251,0.725c-2.116,1.958-2.93,5.057-2.046,7.799
c1.009,3.131,4.027,5.282,7.321,5.202c3.165-0.077,5.983-2.194,6.953-5.202C211.753,346.413,210.548,342.782,207.774,340.999
C207.373,340.729,208.194,341.269,207.774,340.999z'
            />
            <path
                d='M179.793,263.369c-2.609-6.169-11.689-6.008-13.983,0.341c-2.321,6.425,4.861,12.388,10.759,9.046
C179.797,270.926,181.246,266.804,179.793,263.369z'
            />
            <path
                d='M179.793,290.369c-0.378-0.913-0.933-1.732-1.62-2.44c-2.926-2.849-7.692-2.907-10.61,0
c-2.66,2.651-2.893,6.998-0.586,9.947c2.149,2.747,6.061,3.622,9.183,2.097C179.649,298.269,181.315,293.966,179.793,290.369
C179.603,289.909,179.984,290.819,179.793,290.369z'
            />
            <path
                d='M179.793,317.369c-0.378-0.913-0.933-1.732-1.62-2.44c-2.927-2.849-7.691-2.907-10.61,0
c-2.588,2.577-2.89,6.756-0.763,9.712c2.169,3.014,6.383,3.946,9.635,2.191C179.75,325.041,181.269,320.858,179.793,317.369
C179.603,316.909,179.984,317.819,179.793,317.369z'
            />
            <path
                d='M179.793,344.358c-2.542-6.004-11.328-6.057-13.85,0c-1.227,2.948-0.399,6.471,2.027,8.551
c2.391,2.05,5.86,2.393,8.598,0.841C179.818,351.91,181.22,347.809,179.793,344.358
C179.603,343.909,179.984,344.819,179.793,344.358z'
            />
            <path
                d='M149.043,263.369c-2.06-4.87-8.452-6.108-12.23-2.44c-3.992,4.112-2.102,11.156,3.483,12.581
C146.113,274.995,151.4,268.94,149.043,263.369z'
            />
            <path
                d='M149.043,290.369c-2.064-4.879-8.448-6.112-12.23-2.44c-3.997,4.118-2.096,11.145,3.483,12.577
C146.112,301.999,151.399,295.939,149.043,290.369z'
            />
            <path
                d='M149.043,317.369c-2.029-4.902-8.469-6.091-12.23-2.44c-4.107,4.231-1.964,11.462,3.841,12.66
C146.382,328.77,151.333,322.781,149.043,317.369C148.853,316.909,149.234,317.819,149.043,317.369z'
            />
            <path
                d='M149.043,344.369c-2.538-6.131-11.523-6.007-13.916,0.171c-1.131,2.918-0.286,6.331,2.094,8.37
c2.389,2.048,5.861,2.393,8.598,0.841C149.047,351.922,150.497,347.806,149.043,344.369
C148.853,343.909,149.234,344.819,149.043,344.369z'
            />
            <path
                d='M269.988,303.735c-14.061,0-25.499,11.439-25.499,25.499s11.438,25.499,25.499,25.499s25.499-11.439,25.499-25.499
S284.048,303.735,269.988,303.735z M269.988,339.733c-5.789,0-10.499-4.71-10.499-10.499s4.71-10.499,10.499-10.499
s10.499,4.71,10.499,10.499S275.777,339.733,269.988,339.733z'
            />
        </g>
    </svg>
);

export default IntercomIcon;
