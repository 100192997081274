import { ICustomMap } from 'types/ICustomMap';
import { textsCommon } from 'texts/index';
import { HelpData } from './types';

// export default {
//     [routes.home]: 'https://www.youtube.com/embed/jQFEjFMwq5E?autoplay=1',
//     [routes.activityFeed.root]: 'https://www.youtube.com/embed/jQFEjFMwq5E?autoplay=1',
//     [routes.users.root]: 'https://www.youtube.com/embed/XwlfSFquo-g?autoplay=1',
//     [routes.userGroups.users]: 'https://www.youtube.com/embed/umID3q31kFY?autoplay=1',
//     [routes.userGroups.accessPoints]: 'https://www.youtube.com/embed/umID3q31kFY?autoplay=1',
//     [routes.sites.accessPoints.root]: 'https://www.youtube.com/embed/0PK1Ido_buM?autoplay=1',
//     [routes.sites.units.root]: 'https://www.youtube.com/embed/0PK1Ido_buM?autoplay=1',
//     [routes.sites.elevators.root]: 'https://www.youtube.com/embed/0PK1Ido_buM?autoplay=1',
//     [routes.sites.floors.root]: 'https://www.youtube.com/embed/0PK1Ido_buM?autoplay=1',
//     [routes.intercomSettings.root]: 'https://www.youtube.com/embed/SI7uwZdHPAk?autoplay=1',
//     [routes.intercomSettings.root]: 'https://www.youtube.com/embed/RxfwFUHUft0?autoplay=1',
//     [routes.visitorPins.root]: 'https://www.youtube.com/embed/YrzgSFUyLXM?autoplay=1',
//     [routes.schedules.root]: 'https://www.youtube.com/embed/YKBEcmvrQfA?autoplay=1',
//     [routes.healthCheck.root]: 'https://www.youtube.com/embed/2UWaYgx-SjM?autoplay=1',
//     [routes.settings.root]: 'https://www.youtube.com/embed/DpnTfuqVZZU?autoplay=1',
//     [routes.hardware.doorControllers.root]: 'https://www.youtube.com/embed/X-9V1w3DkNQ?autoplay=1',
//     [routes.auditLogs.root]: 'https://www.youtube.com/embed/w2kx4SyPNI0?autoplay=1',
// };

export const links: ICustomMap<HelpData> = {
    activityFeed: {
        video: {
            title: textsCommon.overview,
            link: 'https://www.youtube.com/embed/jQFEjFMwq5E?si=2TF0D82T0nzkJgjP',
        },
    },
    users: {
        video: {
            title: 'Overview',
            link: 'https://www.youtube.com/embed/XwlfSFquo-g?si=2TF0D82T0nzkJgjP',
        },
        faq: [
            {
                title: 'How to Add a User',
                link: 'https://support.swiftlane.com/hc/en-us/articles/360062449471-How-to-Add-a-User',
            },
            {
                title: 'How to add Users via the Upload CSV file?',
                link: 'https://support.swiftlane.com/en/articles/9810800-how-to-add-users-via-the-upload-csv-file',
            },
            {
                title: 'How do I edit or view my User details?',
                link: 'https://support.swiftlane.com/en/articles/9810801-how-do-i-edit-or-view-my-user-details',
            },
        ],
    },
    usersProfile: {
        faq: [
            {
                title: 'How do I edit or view my User details?',
                link: 'https://support.swiftlane.com/en/articles/9810801-how-do-i-edit-or-view-my-user-details',
            },
            {
                title: 'How do I assign Access Group(s) to a User?',
                link: 'https://support.swiftlane.com/en/articles/9810803-how-do-i-assign-access-group-s-to-a-user',
            },
        ],
    },
    sitesAccessPoints: {
        video: {
            title: textsCommon.overview,
            link: 'https://www.youtube.com/embed/0PK1Ido_buM?si=2TF0D82T0nzkJgjP',
        },
        faq: [
            {
                title: 'How to add a Site?',
                link: 'https://support.swiftlane.com/en/articles/9810810-how-do-i-create-a-site',
            },
            {
                title: 'How do I create an Access Point(s)?',
                link: 'https://support.swiftlane.com/en/articles/9810811-how-do-i-create-an-access-point-s',
            },
        ],
    },
    accessPoint: {
        faq: [
            {
                title: 'Change Access Point Name',
                link: 'https://support.swiftlane.com/hc/en-us/articles/4402426871309-Access-Point-Settings-Change-Access-Point-Name',
            },
            {
                title: 'Assigning Access Group to an Access Point',
                link: 'https://support.swiftlane.com/hc/en-us/articles/4402422035725-Assigning-Access-Group-to-an-Access-Point',
            },
            {
                title: 'Access Point Schedules for Access Groups or Door Unlock Schedule',
                link: 'https://support.swiftlane.com/hc/en-us/articles/4402427054605-Access-Point-Schedules-for-Access-Groups-or-Door-Unlock-Schedule',
            },
            {
                title: 'How do I change SwiftReader settings?',
                link: 'https://support.swiftlane.com/en/articles/9810815-how-do-i-change-swiftreader-settings',
            },
            {
                title: 'Can I change the Intercom labels?',
                link: 'https://support.swiftlane.com/en/articles/9810816-can-i-change-the-intercom-labels',
            },
            {
                title: 'My SwiftReader Access Point app is not locked down? Kiosk Mode',
                link: 'https://support.swiftlane.com/hc/en-us/articles/4402565508877-My-SwiftReader-Access-Point-app-is-not-locked-down-Kiosk-Mode',
            },
            {
                title: 'How do I remote reboot my Swiftreader?',
                link: 'https://support.swiftlane.com/hc/en-us/articles/4402565462669-How-do-I-remote-reboot-my-Swiftreader',
            },
        ],
    },
    accessGroups: {
        video: {
            title: textsCommon.overview,
            link: 'https://www.youtube.com/embed/umID3q31kFY?si=2TF0D82T0nzkJgjP',
        },
        faq: [
            {
                title: 'Access Group Overview',
                link: 'https://support.swiftlane.com/hc/en-us/articles/360062065772-Access-Group-Overview',
            },
            {
                title: 'How to Add an Access Group(s)?',
                link: 'https://support.swiftlane.com/hc/en-us/articles/360062065512-How-to-Add-an-Access-Group-s',
            },
            {
                title: 'How do I add a User to the Access Group?',
                link: 'https://support.swiftlane.com/hc/en-us/articles/360062065632-How-do-I-add-a-User-to-the-Access-Group',
            },
            {
                title: 'How do I know which Access Group has access to which Access Point(s)?',
                link: 'https://support.swiftlane.com/hc/en-us/articles/360062065912-How-do-I-know-which-Access-Group-has-access-to-which-Access-Point-s',
            },
        ],
    },
    intercomDirectory: {
        video: {
            title: textsCommon.overview,
            link: 'https://www.youtube.com/embed/RxfwFUHUft0?si=2TF0D82T0nzkJgjP',
        },
        faq: [
            {
                title: 'How do I assign Users to Front Desk Intercom?',
                link: 'https://support.swiftlane.com/hc/en-us/articles/4402568759565-How-do-I-assign-Users-to-Front-Desk-Intercom',
            },
            {
                title: 'How do I setup Intercom Directory Intercom?',
                link: 'https://support.swiftlane.com/hc/en-us/articles/4402565885325-How-do-I-setup-Intercom-Directory-Intercom',
            },
            {
                title: 'How do I edit the Intercom Directory?',
                link: 'https://support.swiftlane.com/hc/en-us/articles/15521429221389-How-do-I-edit-the-Intercom-Directory',
            },
            {
                title: 'How to route intercom calls to multiple people for an intercom entry?',
                link: 'https://support.swiftlane.com/hc/en-us/articles/17735835800333-How-to-route-intercom-calls-to-multiple-people-for-an-intercom-entry',
            },
            {
                title: "I can't find a User/Resident in the Intercom Directory",
                link: 'https://support.swiftlane.com/hc/en-us/articles/4424520179085-I-can-t-find-a-User-Resident-in-the-Intercom-Directory',
            },
        ],
    },
    frontDeskIntercom: {
        video: {
            title: textsCommon.overview,
            link: 'https://www.youtube.com/embed/SI7uwZdHPAk?si=2TF0D82T0nzkJgjP',
        },
        faq: [],
    },
    qrIntercom: {
        video: {
            title: textsCommon.overview,
            link: 'https://www.youtube.com/embed/vFFOeGAAzLI?si=5TQVcJjso_i1klce',
        },
        faq: [
            {
                title: 'Allow browser permissions (Camera, Mic and Location) to use QR Code intercom',
                link: 'https://www.youtube.com/shorts/aMQMAmNbw_A',
            },
        ],
    },
    visitorPins: {
        video: {
            title: textsCommon.overview,
            link: 'https://www.youtube.com/embed/YrzgSFUyLXM?si=2TF0D82T0nzkJgjP',
        },
        faq: [
            {
                title: 'How do I create a Visitors Pass?',
                link: 'https://support.swiftlane.com/hc/en-us/articles/4402568854413-How-do-I-create-a-Visitors-Pass',
            },
            {
                title: 'How to grant access to delivery companies like Fedex, UPS, USPS, Amazon?',
                link: 'https://support.swiftlane.com/hc/en-us/articles/4434261230733-How-to-grant-access-to-delivery-companies-like-Fedex-UPS-USPS-Amazon',
            },
            {
                title: 'How do I deactivate a Visitors Pass?',
                link: 'https://support.swiftlane.com/hc/en-us/articles/4402566083469-How-do-I-deactivate-a-Visitors-Pass',
            },
            {
                title: 'Can I restrict Visitor Pins to certain Access Groups?',
                link: 'https://support.swiftlane.com/hc/en-us/articles/4404694379789-Can-I-restrict-Visitor-Passes-to-certain-Access-Groups',
            },
            {
                title: 'Settings - Pass Settings',
                link: 'https://support.swiftlane.com/hc/en-us/articles/5625777620237-Settings-Pass-Settings',
            },
        ],
    },
    schedules: {
        video: {
            title: textsCommon.overview,
            link: 'https://www.youtube.com/embed/YKBEcmvrQfA?si=2TF0D82T0nzkJgjP',
        },
        faq: [
            {
                title: 'How do I create Schedules?',
                link: 'https://support.swiftlane.com/hc/en-us/articles/4402426546573-How-do-I-create-Schedules',
            },
        ],
    },
    integrations: {
        faq: [
            {
                title: 'Want to transfer your user base to the Admin Dashboard easily?',
                link: 'https://support.swiftlane.com/hc/en-us/articles/4402421432461-Integrations',
            },
        ],
    },
    integrationsRealPage: {
        faq: [
            {
                title: 'RealPage - Integration Configuration',
                link: 'https://support.swiftlane.com/hc/en-us/articles/8528933954701-RealPage-Integration-Configuration',
            },
            {
                title: 'RealPage - Preparing for integration with Swiftlane',
                link: 'https://support.swiftlane.com/hc/en-us/articles/4885163044493-RealPage-Preparing-for-integration-with-Swiftlane',
            },
        ],
    },
    integrationsYardi: {
        faq: [
            {
                title: 'Yardi - Integration Configuration',
                link: 'https://support.swiftlane.com/hc/en-us/articles/8528206816141-Yardi-Integration-Configuration',
            },
            {
                title: 'Yardi Integration - Preparing for Yardi configuration',
                link: 'https://support.swiftlane.com/hc/en-us/articles/8468884079629-Yardi-Integration-Preparing-for-Yardi-configuration',
            },
        ],
    },
    integrationsEntrate: {
        faq: [
            {
                title: 'Entrata - Preparing for Integration with Swiftlane',
                link: 'https://support.swiftlane.com/hc/en-us/articles/8529582748173-Entrata-Preparing-for-Integration-with-Swiftlane',
            },
            {
                title: 'Entrata - Integration Configuration',
                link: 'https://support.swiftlane.com/hc/en-us/articles/8529324244109-Entrata-Integration-Configuration',
            },
        ],
    },
    integrationsBrivo: {
        faq: [
            {
                title: 'Brivo - Swiftlane Sync',
                link: 'https://support.swiftlane.com/hc/en-us/articles/17163488252301-Brivo-Swiftlane-Sync',
            },
            {
                title: 'Brivo - Configure Swiftlane-Brivo Connection for Sync',
                link: 'https://support.swiftlane.com/hc/en-us/articles/17163210982157-Brivo-Configure-Swiftlane-Brivo-Connection-for-Sync',
            },
            {
                title: 'Brivo - Enable Integration from Swiftlane Admin Dashboard',
                link: 'https://support.swiftlane.com/hc/en-us/articles/17162922787341-Brivo-Enable-Integration-from-Swiftlane-Admin-Dashboard',
            },
            {
                title: 'Brivo Integration - Preparing/Enabling for the Swiftlane',
                link: 'https://support.swiftlane.com/hc/en-us/articles/17162578159373-Brivo-Integration-Preparing-Enabling-for-the-Swiftlane',
            },
        ],
    },
    integrationsYaleAugust: {
        faq: [
            {
                title: 'August/Yale Smart Locks',
                link: 'https://support.swiftlane.com/hc/en-us/articles/11973935273869-August-Yale-Smart-Locks',
            },
        ],
    },
    integrationsEagleEye: {
        faq: [
            {
                title: 'Eagle Eye Integration',
                link: 'https://support.swiftlane.com/hc/en-us/articles/8829965981581-Eagle-Eye-Integration',
            },
        ],
    },
    settings: {
        video: {
            title: textsCommon.overview,
            link: 'https://www.youtube.com/embed/DpnTfuqVZZU?si=2TF0D82T0nzkJgjP',
        },
        faq: [
            {
                title: 'Settings - Privacy & Security',
                link: 'https://support.swiftlane.com/hc/en-us/articles/360062457611-Settings-Privacy-Security',
            },
        ],
    },
    hardware: {
        video: {
            title: textsCommon.overview,
            link: 'https://www.youtube.com/embed/X-9V1w3DkNQ?si=2TF0D82T0nzkJgjP',
        },
        faq: [
            {
                title: 'How do you assign the relays to the appropriate Access Point(s)?',
                link: 'https://support.swiftlane.com/hc/en-us/articles/360062066932-How-do-you-assign-the-relays-to-the-appropriate-Access-Point-s',
            },
            {
                title: 'Relay timing changes?',
                link: 'https://support.swiftlane.com/hc/en-us/articles/14852928979213-Relay-timing-changes',
            },
            {
                title: 'AppFolio Integration Set Up',
                link: 'https://support.swiftlane.com/hc/en-us/articles/28423284034701-Swiftlane-AppFolio-Integration',
            },
        ],
    },
};
