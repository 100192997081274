import React, { createContext, ReactNode, useContext, useEffect, useRef, useCallback, useMemo, MutableRefObject } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export type PrevPath = {
    pathname: string;
    search: string;
    fullPath: string;
};

const initialState: PrevPath[] = [];

const PreviousPathContext = createContext<{
    prevPathList: MutableRefObject<PrevPath[]>;
    navigateToLatestPrevPath: (fallbackRoute?: string) => void;
}>({
    prevPathList: { current: initialState },
    navigateToLatestPrevPath: () => {},
});

export const PreviousPathProvider = ({ children }: { children: ReactNode }) => {
    const { search, pathname } = useLocation();
    const { push } = useHistory();
    const prevPathList = useRef<PrevPath[]>(initialState);

    useEffect(() => {
        // Add current path to the list when it changes
        prevPathList.current = [...prevPathList.current, { pathname, search, fullPath: `${pathname}${search}` }];
    }, [pathname, search]);

    const navigateToLatestPrevPath = useCallback(
        (fallbackRoute: string = '/') => {
            if (prevPathList.current.length > 1) {
                // Remove the current path (last entry in the list)
                prevPathList.current.pop();

                // Get the previous route
                const latestPrevPath = prevPathList.current.pop();
                if (latestPrevPath) {
                    push(latestPrevPath.fullPath);
                }
            } else {
                // Navigate to fallback route if no previous path is available
                push(fallbackRoute);
            }
        },
        [push],
    );

    return (
        <PreviousPathContext.Provider value={useMemo(() => ({ prevPathList, navigateToLatestPrevPath }), [navigateToLatestPrevPath])}>
            {children}
        </PreviousPathContext.Provider>
    );
};

export function usePreviousPath() {
    return useContext(PreviousPathContext);
}
