import { EIconColorType, MenuSelectBoxType, TSideNavMenu } from 'types/TSideNavMenu';
import { textsCommon, textsUser } from 'texts/index';
import routes from 'config/routes';
import SolutionIcon from 'components/common/Icons/SolutionIcon';
import featureFlagsList from 'config/featureFlagsList';
import {
    ClusterOutlined,
    DatabaseOutlined,
    DollarCircleOutlined,
    GiftOutlined,
    SettingOutlined,
    WindowsOutlined,
    UnorderedListOutlined,
    PayCircleOutlined,
    FileTextOutlined,
    DeliveredProcedureOutlined,
} from '@ant-design/icons';
import PinIcon from 'components/common/Icons/Pin';
import ActivityIcon from 'components/common/Icons/Activity';
import IntegrationIcon from 'components/common/Icons/Integration';
import UsersIcon from 'components/common/Icons/Users';
import AuditLogsIcon from 'components/common/Icons/AuditLogsIcon';
import LocksIcon from 'components/common/Icons/Locks';
import HeartIcon from 'components/common/Icons/HeartIcon';
import SchedulesIcon from 'components/common/Icons/Schedules';
import VisitorAccessIcons from 'components/common/Icons/VisitorAccess';
import UserIcon from 'components/common/Icons/User';
import FileIcon from 'components/common/Icons/File';
import WorkspaceIcon from 'components/common/Icons/Workspace';
import PhoneIcon from 'components/common/Icons/Phone';
import ChipIcon from 'components/common/Icons/Chip';
import PinCodeIcon from 'components/common/Icons/PinCode';
import PhoneCallIcon from 'components/common/Icons/PhoneCall';
import AnnouncementIcon from 'components/common/Icons/Announcement';
import Chat from 'components/common/Icons/Chat';
import ContentEdit from 'components/common/Icons/ContentEdit';
import OpenAI from 'components/common/Icons/OpenAI';
import Images from 'components/common/Icons/Images';
import IntercomIcon from 'components/common/Icons/Intercom';

export const accessItems: TSideNavMenu[] = [
    {
        title: textsCommon.navigation.accessControl.root,
        icon: PhoneIcon,
        items: [
            {
                icon: ActivityIcon,
                title: textsCommon.navigation.accessControl.activityFeed,
                route: routes.activityFeed.root,
            },
            {
                icon: UserIcon,
                title: textsCommon.navigation.accessControl.users,
                route: routes.users.root,
            },
            {
                icon: UsersIcon,
                title: textsCommon.navigation.accessControl.accessGroups,
                route: routes.userGroups.root,
            },
            {
                icon: PinIcon,
                title: textsCommon.navigation.accessControl.sitesAccessPoints,
                route: routes.sites.root,
            },
            {
                icon: IntercomIcon,
                title: textsCommon.navigation.accessControl.swiftCall,
                route: routes.swiftCall.root,
                iconColorType: EIconColorType.fill,
                workspaceSettingFlag: 'enable_call_box_integration',
            },
            {
                icon: AnnouncementIcon,
                title: textsCommon.navigation.accessControl.announcements,
                route: routes.announcements.root,

                iconColorType: EIconColorType.fill,
            },
            {
                icon: PhoneCallIcon,
                title: textsCommon.navigation.accessControl.visitorAccess,
                route: routes.intercomSettings.root,
            },
            {
                icon: PinCodeIcon,
                title: textsCommon.navigation.accessControl.visitorPins,
                route: routes.visitorPins.root,
            },
            {
                icon: SchedulesIcon,
                title: textsCommon.navigation.accessControl.schedules,
                route: routes.schedules.root,
            },
            {
                icon: HeartIcon,
                title: textsCommon.navigation.accessControl.healthCheck,
                route: routes.healthCheck.root,
                launchDarklyFlag: featureFlagsList.enableSwiftHealth,
                workspaceSettingFlag: 'enable_health_check',
            },
            {
                icon: IntegrationIcon,
                title: textsCommon.navigation.accessControl.integrations,
                route: routes.integrations.root,
                launchDarklyFlag: featureFlagsList.enableOktaIntegration,
            },
            {
                icon: SettingOutlined,
                title: textsCommon.navigation.accessControl.settings,
                route: routes.settings.root,
                launchDarklyFlag: featureFlagsList.securitySettings,
                iconColorType: EIconColorType.fill,
            },
            {
                icon: FileIcon,
                title: textsCommon.navigation.accessControl.reports,
                route: routes.reports.root,
                launchDarklyFlag: featureFlagsList.reports,
            },
            {
                icon: LocksIcon,
                title: textsCommon.navigation.accessControl.hardware,
                route: routes.hardware.doorControllers.root,
                showOpenInNewTab: true,
            },
            {
                icon: AuditLogsIcon,
                title: textsCommon.navigation.accessControl.auditLogs,
                route: routes.auditLogs.root,
                launchDarklyFlag: featureFlagsList.enableAuditLogs,
            },
            {
                icon: FileTextOutlined,
                title: textsCommon.navigation.billing.invoices,
                route: routes.billing.root,
                iconColorType: EIconColorType.fill,
                isBilling: true,
            },
            {
                icon: DeliveredProcedureOutlined,
                title: textsCommon.navigation.accessControl.packageDelivery,
                route: routes.packageDelivery.root,
                iconColorType: EIconColorType.fill,
                launchDarklyFlag: featureFlagsList.enablePackageScan,
            },
        ],
    },
];

export const rentGptItems: TSideNavMenu[] = [
    {
        icon: OpenAI,
        iconColorType: EIconColorType.fill,
        title: textsCommon.navigation.rentGpt.root,
        launchDarklyFlag: featureFlagsList.enableRentGpt,
        workspaceSettingFlag: 'enable_rent_gpt',
        items: [
            {
                selectBoxType: MenuSelectBoxType.renGpt,
            },
            {
                icon: UsersIcon,
                title: textsCommon.navigation.rentGpt.leadActions,
                route: `${routes.rentGpt.root}${routes.rentGpt.leadActions.root}`,
            },
            {
                icon: Chat,
                iconColorType: EIconColorType.fill,
                title: textsCommon.navigation.rentGpt.chatHistory,
                route: `${routes.rentGpt.root}${routes.rentGpt.chatBotSessions}`,
            },
            {
                icon: UnorderedListOutlined,
                iconColorType: EIconColorType.fill,
                title: textsCommon.navigation.rentGpt.formSubmissions,
                route: `${routes.rentGpt.root}${routes.rentGpt.formSubmissions.root}`,
            },
            {
                icon: ContentEdit,
                iconColorType: EIconColorType.fill,
                title: textsCommon.navigation.rentGpt.contentModeration,
                route: `${routes.rentGpt.root}${routes.rentGpt.contentModeration.root}`,
            },
            {
                icon: Images,
                iconColorType: EIconColorType.fill,
                title: textsCommon.navigation.rentGpt.gallery,
                route: `${routes.rentGpt.root}${routes.rentGpt.gallery.root}`,
            },
            {
                icon: SettingOutlined,
                iconColorType: EIconColorType.fill,
                title: textsCommon.navigation.rentGpt.settings,
                route: `${routes.rentGpt.root}${routes.rentGpt.settings.root}${routes.rentGpt.settings.actions}`,
            },
        ],
    },
];
export const commandCenterItems: TSideNavMenu[] = [
    {
        title: textsCommon.navigation.commandCenter.root,
        icon: LocksIcon,
        isCommandCenter: true,
        items: [
            {
                icon: PhoneIcon,
                title: textsCommon.navigation.commandCenter.swiftReaders,
                route: routes.commandCenter.swiftReaders.root,
                launchDarklyFlag: featureFlagsList.enableIosDevices,
                showOpenInNewTab: true,
            },
            {
                icon: PhoneIcon,
                title: textsCommon.navigation.commandCenter.deviceManager,
                route: routes.commandCenter.deviceManager.root,
                showOpenInNewTab: true,
            },
            {
                icon: WorkspaceIcon,
                title: textsCommon.navigation.commandCenter.workspaces,
                route: routes.commandCenter.workspace.root,
                launchDarklyFlag: featureFlagsList.enableWorkspaces,
                showOpenInNewTab: true,
                iconColorType: EIconColorType.fill,
            },
            {
                icon: ChipIcon,
                title: textsCommon.navigation.commandCenter.doorControllers,
                route: routes.commandCenter.dcu.root,
                launchDarklyFlag: featureFlagsList.enableDcuDevices,
                showOpenInNewTab: true,
            },
            {
                icon: PayCircleOutlined,
                title: textsCommon.navigation.commandCenter.billing,
                route: `${routes.commandCenter.root}${routes.commandCenter.billing.root}`,
                iconColorType: EIconColorType.fill,
                showOpenInNewTab: true,
            },
            {
                icon: FileTextOutlined,
                title: textsCommon.navigation.commandCenter.invoices,
                route: `${routes.commandCenter.root}${routes.commandCenter.invoices.root}`,
                iconColorType: EIconColorType.fill,
                showOpenInNewTab: true,
            },
            {
                icon: OpenAI,
                iconColorType: EIconColorType.fill,
                title: textsCommon.navigation.rentGpt.root,
                launchDarklyFlag: featureFlagsList.enableRentGpt,
                route: `${routes.commandCenter.root}${routes.commandCenter.rentGpt.root}`,
                showOpenInNewTab: true,
            },
            {
                icon: DatabaseOutlined,
                title: textsCommon.navigation.commandCenter.inventoryManagement,
                route: routes.commandCenter.inventoryManagement.root,
                showOpenInNewTab: true,
                iconColorType: EIconColorType.fill,
            },
            {
                icon: ClusterOutlined,
                title: textsCommon.navigation.commandCenter.acuHub,
                route: routes.commandCenter.acuHub.root,
                showOpenInNewTab: true,
                iconColorType: EIconColorType.fill,
            },
            {
                icon: UserIcon,
                title: textsCommon.navigation.commandCenter.users,
                route: routes.commandCenter.users.root,
                launchDarklyFlag: featureFlagsList.enableWorkspaces,
                showOpenInNewTab: true,
            },
            {
                icon: PinIcon,
                title: textsCommon.navigation.commandCenter.sites,
                route: routes.commandCenter.sites,
                showOpenInNewTab: true,
                launchDarklyFlag: featureFlagsList.enableWorkspaces,
            },
            {
                icon: WindowsOutlined,
                title: textsCommon.navigation.commandCenter.vms,
                route: routes.commandCenter.vms.root,
                launchDarklyFlag: featureFlagsList.enableWorkspaces,
                showOpenInNewTab: true,
                iconColorType: EIconColorType.fill,
            },
            {
                icon: SolutionIcon,
                title: textsCommon.navigation.commandCenter.userFeedback,
                route: routes.commandCenter.userFeedback.root,
                launchDarklyFlag: featureFlagsList.enableWorkspaces,
                showOpenInNewTab: true,
                iconColorType: EIconColorType.fill,
            },
            {
                icon: GiftOutlined,
                title: textsCommon.navigation.commandCenter.referralAndOffers,
                route: routes.commandCenter.referrals.offers,
                launchDarklyFlag: featureFlagsList.enableReferralsOffers,
                iconColorType: EIconColorType.fill,
            },
            {
                icon: AuditLogsIcon,
                title: textsCommon.navigation.commandCenter.attUsageTracking,
                route: `${routes.commandCenter.root}${routes.commandCenter.attUsageTracking.root}`,
                showOpenInNewTab: true,
            },
            {
                icon: AuditLogsIcon,
                title: textsCommon.navigation.commandCenter.accessLogs,
                route: routes.commandCenter.readAccessLogs.root,
                launchDarklyFlag: featureFlagsList.enableWorkspaces,
                showOpenInNewTab: true,
            },
            // {
            //     icon: TailGatingIcon,
            //     title: textsCommon.navigation.tailgatingDetection,
            //     route: routes.tailgatingDetection.root,
            //
            //     launchDarklyFlag: featureFlagsList.enableTailgateDetection,
            // },
        ],
    },
];

export const adminItems: TSideNavMenu[] = [
    ...accessItems,
    ...rentGptItems,
    ...commandCenterItems,
    {
        icon: AuditLogsIcon,
        title: textsCommon.navigation.help,
        route: routes.helpSupport.root,
        launchDarklyFlag: featureFlagsList.enableHelpSupport,
    },
];

export const userDashboardItems: TSideNavMenu[] = [
    {
        icon: ActivityIcon,
        title: textsCommon.navigation.accessControl.activityFeed,
        route: routes.user.feed,
    },
    {
        icon: UserIcon,
        title: textsCommon.navigation.accessControl.myProfile,
        route: routes.user.profile.root,
    },
    {
        icon: VisitorAccessIcons,
        title: textsUser.visitorPasses,
        route: routes.user.passes,
    },
    {
        icon: PinIcon,
        title: textsUser.doorAccess,
        route: routes.user.access,
    },
    {
        icon: LocksIcon,
        title: textsUser.credentials,
        route: routes.user.credentials,
    },
    {
        icon: DollarCircleOutlined,
        title: textsCommon.navigation.accessControl.subscriptions,
        route: routes.user.subscriptions,
        iconColorType: EIconColorType.fill,
    },
    {
        icon: AnnouncementIcon,
        title: textsCommon.navigation.accessControl.announcements,
        route: routes.announcements.root,
        iconColorType: EIconColorType.fill,
    },
];

export const sideNavMenuOpenKeysKey = 'sideNavMenuOpenKeys';
export const multiAccountAutoLoginQueryKey = 'multiAccountAutoLogin';
